import React from "react"
import { Layout } from "../components/common"
import Employee from "../components/Employee"
import { Helmet } from "react-helmet"
const Team = () => (
  <Layout>
    <Helmet>
      <title>Team</title>
    </Helmet>
    <Employee />
  </Layout>
)

export default Team
