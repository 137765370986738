import React from "react"
import bandWave from "./assets/images/bg/banner4-wave1.svg"
import waterMark from "./assets/images/bg/watermark2-bg.png"
import waterMark2 from "./assets/images/bg/watermark2-bg.png"

const Employee = () => {
  return (
    <>
      <div className="banner-section4" style={{ minHeight: "60vh" }}>
        <img
          src={bandWave}
          className="banner4-wave1 img-fluid"
          alt="Wave"
        />
        <img
          src={waterMark2}
          className="banner4-wave2 img-fluid"
          alt="Wave 1"
        />

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 col-sm-10">
              <div
                className="section-title3 primary4 text-cener"
                style={{ marginBottom: "12px" }}
              >
                <span>-Team-</span>
                <h3>Meet Our Team Of Creative Minds </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="service-section4 pt-120 pb-120 position-relative overflow-hidden"
        style={{ paddingTop: "25px", paddingBottom: "25px" }}
      >
        <img
          src={waterMark}
          className="watermark1-bg"
          alt="watermark background"
        />
        <div className="container">
          <div
            class="row d-flex justify-content-center g-4"
            style={{ marginBottom: "55px", marginTop: "25px" }}
          >
            <div class="col-xl-6 col-lg-4 col-md-6 col-sm-10">
              <div
                class="single-team2 style-4 wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="0.2s"
              >
                <div class="team-content" style={{ minHeight: "530px" }}>
                  <h4 class="name">Yvonne</h4>
                  <p class="designation">Chief Executive Officer (CEO)</p>
                  <p className="para" style={{ marginTop: "15px" }}>
                    BA (Hons) Service Sector Management; HND Business
                    Administration with Travel and Tourism; Learning with Perl
                    (IT); Business entrepreneur and founder of Creative Cheese
                    Works; Company Director, consultancy firm providing
                    management systems to companies primarily in the Space
                    Sector including the European Space Agency; Engineering
                    Co-ordinator with Scottish Power; Assessment Co-ordinator
                    BMT Quality Assessors; Qualification in Dutch Language; Book
                    club advocate and book reviewer; Business leader,
                    strategist, assessment, and planning progress towards
                    business goals.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-10">
              <div
                class="single-team2 style-4 wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="0.4s"
              >
                <div class="team-content" style={{ minHeight: "530px" }}>
                  <h4 class="name">Pamela</h4>
                  <p class="designation">Editor-in Chief</p>
                  <p className="para" style={{ marginTop: "15px" }}>
                    Master of Arts Creative Writing (‘distinction’) Teesside
                    University; Bachelor of Arts (Hons) English Literature and
                    Creative Writing (Open University); Community Drama,
                    University of Ulster; Media Studies, Foyle Arts Centre,
                    Derry; Author{" "}
                    <i>
                      ‘Storytelling Training Manual for Community Facilitators
                      and Workshop Directors’
                    </i>
                    : A ‘How To’ for delivering Advocacy for Peace Workshops;
                    delivery, facilitation and training based on the text.
                    Editor <i>Time In</i>
                    (prison magazine); Co-Editor All In magazine;
                    <i>Writer-in-Residence </i> HMP Magilligan;{" "}
                    <i>Trainer Assessor </i>
                    North West Regional College. Poet and author, with readings
                    and published work in Ireland, UK, Holland including Buffalo
                    Circle New Mexico with Jimmy Santiago Baca. Online content
                    writer/designer.{" "}
                    <a
                      href="https://www.pamelamarybrown.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.pamelamarybrown.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row d-flex justify-content-center g-4">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-10">
              <div
                class="single-team2 style-4 wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="0.6s"
              >
                <div class="team-content" style={{ minHeight: "380px" }}>
                  <h4 class="name">Sean</h4>
                  <p class="designation">Gaelic Language and Digital Media</p>
                  <p className="para" style={{ marginTop: "15px" }}>
                    Bachelor of Arts in Irish Language and Literature (Hons 1st
                    Class), University of Ulster, Magee; Highest Award citation
                    Faculty of Arts, University of Ulster (2005/06); Gaeilgeoir
                    and Irish Language Teacher; Master Craftsman; Piano
                    Tuner/Technician.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-10">
              <div
                class="single-team2 style-4 wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="0.6s"
              >
                <div class="team-content" style={{ minHeight: "380px" }}>
                  <h4 class="name">Jonathan</h4>
                  <p class="designation">Digital Media, Sales and Promotion</p>
                  <p className="para" style={{ marginTop: "15px" }}>
                    Entrepreneur; aficionado digital platforms; social media;
                    innovation/motivation in online marketing; hospitality, arts
                    and entertainment industries, ten years’ experience; events,
                    festivals, bookings; visitors and promotion of footfall;
                    worked in the care industry; Gaeilgeoir and photographer.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-10">
              <div
                class="single-team2 style-4 wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="0.6s"
              >
                <div class="team-content" style={{ minHeight: "380px" }}>
                  <h4 class="name">Garvan</h4>
                  <p class="designation">Website Digital Media</p>
                  <p className="para" style={{ marginTop: "15px" }}>
                    BSc (Hons) Computer Science, University of Ulster, Magee;
                    Web Design; Web Development; Web Manager; App designer;
                    Hospitality, arts and entertainment industrie
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Employee
